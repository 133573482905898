
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 Require all svg in sprite folder to make a sprite
 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

function requireAll(r) {
  r.keys().forEach(r)
}
requireAll(require.context('../svg/sprite/', true, /\.svg$/))

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 JS
 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

$( document ).ready(function() {

  $('[data-vbg]').youtube_background();

  function showOverlay(){
    $('body').addClass('overlayed');
    $('#subscription_form').removeClass('hidden');
  }

  function hideOverlay(){
    $('body').removeClass('overlayed');
    $('#subscription_form').addClass('hidden');
  }

  function showOtherPerson() {
    $('#notalone').removeClass('hidden')
  }

  function hideOtherPerson() {
    $('#notalone').addClass('hidden')
  }

  function isAccompagned() {
    let alone = $('input[name="inscription[accompagne]"]:checked').val();
    if(alone === 'Oui') {
      return true
    } else {
      return false;
    }
  }

  $('.inscription-button').on('click', function(){
    showOverlay();
  })

  $('.logo').on('click', function (e){
    e.preventDefault();
    hideOverlay()
  })

  $('#inscription_accompagne input').on('change', function(e){
    if(isAccompagned()) {
      showOtherPerson();
    } else {
      hideOtherPerson();
    }
  })

  $('#inscription_submit').on('click', function(e){
    e.preventDefault();
    let errors = false;
    let form = $('#subscription_form form');
    let notAlone = $('input[name="inscription[accompagne]"]:checked').val();
    if(notAlone == 'Oui') {
      let nom2 = $('#inscription_nom2').val().trim();
      let prenom2 = $('#inscription_prenom2').val().trim();
      if(nom2 == '' && prenom2 == '') {
        alert('Veuillez indiquez le nom et prénom de la personne vous accompagnant');
        errors = true;
      } else {
        if(nom2 == '') {
          alert('Veuillez indiquez le nom de la personne vous accompagnant');
          errors = true;
        }
        if(prenom2 == '') {
          alert('Veuillez indiquez le prenom de la personne vous accompagnant');
          errors = true;
        }
      }
    }
    if(errors === false) {
      form.submit();
    }
  })

});
